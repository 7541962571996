import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { spacing, media, box, tokens } from '@tokens'
import { motion } from 'framer-motion'
import Loader from '@elements/Loader'
import PostListInner from './PostListInner'

export const PostList = styled.section`
  ${box({
    spacing: 'top',
    multiplier: 0.5,
    type: 'margin',
  })};
  ${box({
    spacing: 'bottom',
    type: 'margin',
  })};
  ${box({
    spacing: 'x',
  })};

  .loader > div {
    margin-bottom: ${spacing(4)};
  }
`

export default ({ className = '', posts, isFiltered = false, isLoading }) => {
  return (
    <PostList className={className}>
      <Loader className="loader" isLoading={isLoading} />
      <PostListInner
        className="post-list-inner"
        isLoading={isLoading}
        posts={posts}
        isFiltered={isFiltered}
      />
    </PostList>
  )
}

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
}
