import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media, box } from '@tokens'
import { motion } from 'framer-motion'

import {
  FilterOptionColumnsBase,
  FilterCategoryContainerBase,
} from '@mixins/MixinsFilter'

export const FilterCategories = styled.ul`
  ${FilterCategoryContainerBase}
`

export const FilterSubCategories = styled(motion.ul)`
  ${FilterOptionColumnsBase}
  pointer-events: ${styledMap('isFilterOpen', {
    true: 'auto',
    default: 'none',
  })};
  ${styledMap('type', {
    child: css`
      ${media.lessThan('s')`
        display: none;
      `}
    `,
    default: '',
  })};
`

export default ({ isFilterOpen, children, className, type }) => {
  if (type === 'child') {
    return (
      <FilterSubCategories
        className={className}
        isFilterOpen={isFilterOpen}
        type={type}
        initial="collapsed"
        animate={isFilterOpen ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: {
            opacity: 1,
            height: 'auto',
            transition: {
              delayChildren: 0.1,
              duration: 0.4,
              staggerChildren: 0.1,
            },
          },
          collapsed: {
            opacity: 0,
            height: 0,
            transition: {
              duration: 0.4,
            },
          },
        }}
      >
        {children}
      </FilterSubCategories>
    )
  }

  return <FilterCategories className={className}>{children}</FilterCategories>
}
