import { css } from 'styled-components'
import { media, tokens, spacing } from '@tokens'

export const FilterOptionColumnsBase = css`
  margin-left: ${spacing()};
  margin-bottom: 0;

  ${media.greaterThan('s')`
    width: 8rem
  `}

  ${media.greaterThan('m')`
    width: 10rem
  `}

  &:first-child {
    margin-left: 0;
  }

  ${media.lessThan('376px')`
    width: 33%;
  `}
`

export const FilterCategoryContainerBase = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 0;

  ${media.greaterThan('s')`
    justify-content: flex-start;
  `}

  ${media.lessThan('376px')`
    width: 100%;
  `}
`
