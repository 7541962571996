import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media, box } from '@tokens'
import { FilterCategoryContainerBase } from '@mixins/MixinsFilter'
import { motion } from 'framer-motion'

export const FilterCategoryContainer = styled(motion.div)`
  ${FilterCategoryContainerBase}
`

export default ({ children, className, type }) => {
  return (
    <FilterCategoryContainer className={className} type={type}>
      {children}
    </FilterCategoryContainer>
  )
}
