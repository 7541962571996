import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media, box } from '@tokens'
import { ContainerMaxWidth } from '@styles/Global'
import ArrowDown from '@svgs/ArrowDown'
import { motion, AnimatePresence } from 'framer-motion'
import FilterCategoryContainer from './FilterCategoryContainer'
import FilterCategories from './FilterCategories'
import FilterList from './FilterList'
import FilterToggle from './FilterToggle'

export const Filter = styled.div`
  ${box({
    spacing: 'x',
  })}
  width: 100%;

  .inner {
    ${ContainerMaxWidth}
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: ${spacing()};
    border-top: 1px solid ${tokens.color('filter.border')};
    margin-bottom: ${spacing(-1)};

    ${media.greaterThan('m')`
      display: flex;
      flex-flow: row;
      width: 100%;
    `}
  }

  .filter-bar {
    margin-bottom: ${spacing(1)};

    ${media.greaterThan('s')`
      margin-right: ${spacing(1)};
    `}

    ${media.lessThan('s')`
      width: 100%;
    `}
  }
`

export default ({
  className = '',
  categories,
  updateFilter,
  activeFilters,
}) => {
  const classes = `post-filter ${className}`
  const [isFilterOpen, setFilterOpen] = useState(false)

  const toggleFilter = (e) => {
    setFilterOpen(!isFilterOpen)
  }

  return (
    <Filter className={classes}>
      <div className="inner">
        {categories && (
          <div className="filter-bar">
            <FilterCategoryContainer type="parent" key="parent">
              <FilterCategories type="parent">
                <FilterList
                  filters={categories}
                  updateFilter={updateFilter}
                  activeFilters={activeFilters}
                  type="parent"
                />
              </FilterCategories>
            </FilterCategoryContainer>
            <AnimatePresence initial={false}>
              {isFilterOpen && (
                <FilterCategoryContainer type="child" key="child">
                  {categories &&
                    categories.map((category) => {
                      return (
                        <FilterCategories
                          key={`${category.name}-sub-list`}
                          isFilterOpen={isFilterOpen}
                          type="child"
                        >
                          <FilterList
                            filters={category.subCategories}
                            updateFilter={updateFilter}
                            activeFilters={activeFilters}
                            type="child"
                          />
                        </FilterCategories>
                      )
                    })}
                </FilterCategoryContainer>
              )}
            </AnimatePresence>
          </div>
        )}
        <FilterToggle
          className="sub-category-toggle"
          toggleFilter={toggleFilter}
          isFilterOpen={isFilterOpen}
        >
          {isFilterOpen ? 'Close' : 'Filters'}
          <ArrowDown direction={isFilterOpen ? 'up' : 'down'} />
        </FilterToggle>
      </div>
    </Filter>
  )
}
