import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Container from '@layout/Container'
import FilterWithPostList from '@components/Filter/FilterWithPostList'
import {
  formatTemplateData,
  formatCategoriesData,
  formatPostData,
} from '@utils/templates'

export const IndexPage = ({ data }) => {
  const {
    allWpPost: { nodes: posts },
    allWpCategory: { nodes: categories },
  } = data

  const template = {
    activeSlug: 'events-stories',
    heroType: 'archive',
    theme: 'light',
    seo: {
      title: `Events & Stories | Hoyne Brewing Co. | Craft Beer in Victoria`,
      path: '/events-stories/',
    },
  }

  return (
    <>
      <Container template={formatTemplateData(template)}>
        <FilterWithPostList
          posts={formatPostData(posts)}
          categories={formatCategoriesData(categories)}
        />
      </Container>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery($limit: Int!) {
    allWpPost(sort: { fields: date, order: DESC }, limit: $limit) {
      nodes {
        ...TeaserPostFields
      }
    }
    allWpCategory(
      sort: { order: DESC, fields: wpParent___id }
      filter: { databaseId: { ne: 1 } }
    ) {
      nodes {
        id
        slug
        name
        databaseId
        colorCategory
        posts {
          nodes {
            slug
          }
        }
        wpParent {
          databaseId
          slug
        }
      }
    }
  }
`
