import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media, box } from '@tokens'
import { motion } from 'framer-motion'
import { H2Sizes } from '@styles/Typography'

export const FilterOptionButton = styled(motion.button)`
  display: inline-flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  height: ${spacing(5)};
  padding: 0 ${spacing(3)};
  border: none;
  border-radius: ${spacing(3)};
  font-family: ${tokens.get('type.fontFamilyBase')};
  line-height: 1;
  -webkit-appearance: none;
  color: ${tokens.color('black')};
  cursor: pointer;
  background-color: ${styledMap('color', {
    blue: tokens.color('teaser.blue'),
    green: tokens.color('teaser.green'),
    yellow: tokens.color('teaser.yellow'),
    default: styledMap('type', {
      child: tokens.color('button.base.light.background'),
      default: tokens.color('filter.grey.base'),
    }),
  })};

  ${styledMap('type', {
    child: css`
      font-size: 0.875rem;
    `,
    default: css`
      ${H2Sizes}

      font-weight: ${tokens.get('type.fontWeight.bold')};

      ${media.greaterThan('s')`
        width: 100%;
      `}
    `,
  })};

  ${media.lessThan('376px')`
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    justify-content: center;
  `}
`

export default ({
  onClick,
  filter,
  filterId,
  parentId,
  type,
  color,
  isActive,
  children,
}) => {
  return (
    <FilterOptionButton
      onClick={onClick}
      filter={filter}
      data-filter-id={filterId}
      data-parent-id={parentId}
      type={type}
      color={color}
      isActive={isActive}
      animate={isActive}
      variants={{
        active: {
          opacity: 1,
        },
        inactive: {
          opacity: 0.5,
        },
      }}
      whileHover={{
        backgroundColor:
          {
            blue: tokens.color('teaser.hover.blue'),
            green: tokens.color('teaser.hover.green'),
            yellow: tokens.color('teaser.hover.yellow'),
          }[color] || tokens.color('teaser.hover.grey'),
        transition: {
          ease: 'easeOut',
          duration: 0.1,
        },
      }}
    >
      {children}
    </FilterOptionButton>
  )
}
