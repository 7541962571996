import React from 'react'
import Teaser from '@components/Teasers'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { spacing, media, box, tokens } from '@tokens'
import { ContainerMaxWidth } from '@styles/Global'
import {
  PostListGrid,
  PostListColumnGap,
  PostListRowGap,
} from '@mixins/MixinsPostList'

export const PostListInner = styled.section`
  ${ContainerMaxWidth}
  ${PostListGrid}
  ${PostListColumnGap}
  ${PostListRowGap}

  transition: opacity 0.4s linear;
  opacity: ${styledMap('isLoading', {
    true: 0.3,
    default: 1,
  })};
`
export default ({ className = '', posts, isFiltered = false, isLoading }) => {
  return (
    <PostListInner className={className} isLoading={isLoading}>
      {posts.map(
        ({ id, uri, color, category, title, date, ...post }, index) => {
          let type = ''
          if (isFiltered) {
            type = 'default'
          } else {
            switch (index) {
              case 0:
                type = 'primary'
                break
              default:
                type = 'default'
                break
            }
          }

          return (
            <Teaser
              key={id}
              uri={uri}
              color={color}
              type={type}
              category={category}
              title={title}
              date={date}
              image={type === 'primary' ? post.image : ''}
            />
          )
        }
      )}
    </PostListInner>
  )
}
