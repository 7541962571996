import React, { useContext } from 'react'
import { ThemeContext } from '@layout/Layout'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media, box } from '@tokens'
import { MetaStyles } from '@styles/Typography'
import { ButtonStyles } from '@mixins/MixinsButton'

export const FilterToggle = styled.div`
  ${ButtonStyles}
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  ${media.lessThan('760px')`
    display: none;
  `}

  svg {
    position: relative;
    display: block;
    width: ${spacing(3)};
    margin-left: ${spacing()};
    fill: ${styledMap('theme', {
      dark: tokens.color('text', 'light'),
      default: tokens.color('filter.toggle'),
    })};
  }
`

export default ({
  toggleFilter,
  isFilterOpen,
  children,
  className: classes,
}) => {
  const { theme } = useContext(ThemeContext)
  const className = `button--toggle ${classes}`
  return (
    <FilterToggle
      className={className}
      onClick={toggleFilter}
      isOpen={isFilterOpen}
      theme={theme}
      isToggle
    >
      {children}
    </FilterToggle>
  )
}
