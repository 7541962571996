import React, { useState, useEffect } from 'react'
import FilterOptionListItem from './FilterOptionListItem'
import FilterOptionButton from './FilterOptionButton'

export default ({ filters, type, updateFilter, activeFilters }) => {
  return filters.map((filter) => {
    const { name, slug, databaseId, wpParent, color = 'grey' } = filter
    const parentId = wpParent?.databaseId
    const allActive = activeFilters.length === 0
    const parentActive = activeFilters.includes(`${parentId}`)
    const isActive =
      activeFilters.includes(`${databaseId}`) || allActive || parentActive
        ? 'active'
        : 'inactive'

    return (
      <FilterOptionListItem key={slug} type={type}>
        <FilterOptionButton
          className="option"
          onClick={updateFilter}
          filter={slug}
          filterId={databaseId}
          parentId={parentId}
          type={type}
          color={color}
          isActive={isActive}
        >
          {name}
        </FilterOptionButton>
      </FilterOptionListItem>
    )
  })
}
