import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media, box } from '@tokens'
import { FilterOptionColumnsBase } from '@mixins/MixinsFilter'
import { motion } from 'framer-motion'

export const FilterOptionListItem = styled(motion.li)`
  list-style: none;

  ${styledMap('type', {
    child: css`
      margin-left: 0;
      margin-top: ${spacing()};
      transform-origin: '50% 50%';

      &:first-child {
        margin-top: ${spacing(3)};
      }
    `,

    parent: css`
      ${FilterOptionColumnsBase}
    `,
  })};
`

export default ({ children, type }) => {
  return (
    <FilterOptionListItem
      type={type}
      transition={{ type: 'spring' }}
      variants={{
        open: {
          opacity: 1,
          scale: 1,
          transition: {
            ease: 'backInOut',
            duration: 0.2,
          },
        },
        collapsed: {
          opacity: 0,
          scale: 0.8,
          transition: {
            ease: 'backInOut',
            duration: 0.2,
          },
        },
      }}
    >
      {children}
    </FilterOptionListItem>
  )
}
