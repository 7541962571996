import React, { useState, useEffect, useMemo } from 'react'
import gql from 'graphql-tag'
import axios from 'axios'
import PostList from '@components/TeasersList/PostList'
import Filter from '@components/Filter'
import { formatPostData } from '@utils/templates'
import { useSiteSource } from '@hooks/useSiteSource'

const POSTS_SEARCH_QUERY = gql`
  query GET_POSTS($filters: [ID]) {
    posts(
      where: { categoryIn: $filters, orderby: { field: DATE, order: DESC } }
      first: 15
    ) {
      nodes {
        id
        title
        databaseId
        uri
        topCategory {
          name
          color
        }
        eventDetails {
          eventDate
        }
      }

      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`

const FilterWithPostList = ({ posts: initialPosts, categories }) => {
  const initalState = {
    posts: initialPosts,
    pageInfo: {},
    isFiltered: false,
  }
  const siteSourceUrl = useSiteSource()
  const [postsData, setPostsData] = useState(initalState)
  const [filterData, setFilterData] = useState({
    activeFilters: [],
  })
  const [isLoading, setLoading] = useState(false)
  const { posts, isFiltered } = postsData
  const { activeFilters } = filterData

  const filterPosts = (filters) => {
    return axios({
      url: siteSourceUrl,
      method: 'post',
      data: {
        query: POSTS_SEARCH_QUERY.loc.source.body,
        variables: {
          filters,
        },
      },
    })
      .then((result) => {
        return result.data
      })
      .catch((error) => console.dir(error.message))
  }

  const updateFilter = (e) => {
    setLoading(true)
    const targetId = e.target.dataset.filterId
    const targetParentId = e.target.dataset.parentId
    const allToplevelFiltersActive =
      categories.filter((category) => {
        return [targetId, ...activeFilters].includes(`${category.databaseId}`)
      }).length === categories.length
    let currentFilters

    if (allToplevelFiltersActive) {
      currentFilters = []
    } else if (activeFilters.includes(targetId)) {
      currentFilters = activeFilters.filter((catId) => {
        return catId !== targetId
      })
    } else if (activeFilters.includes(targetParentId)) {
      currentFilters = [targetId, ...activeFilters].filter((catId) => {
        return catId !== targetParentId
      })
    } else {
      currentFilters = [targetId, ...activeFilters]
    }

    setFilterData({
      activeFilters: currentFilters,
    })

    if (currentFilters.length) {
      const resultData = filterPosts(currentFilters)
      resultData.then(({ data }) => {
        if (data && data.posts) {
          setPostsData({
            posts: formatPostData(data.posts.nodes),
            pageInfo: data.posts.pageInfo,
            isFiltered: !!currentFilters.length,
          })
        }
      })
    } else {
      setTimeout(() => {
        setPostsData(initalState)
        setLoading(false)
      }, 100)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search)
      const urlTargetId = urlParams.get('targetId')
      const urlParentId = urlParams.get('parentId')
      setLoading(true)
      if (urlTargetId) {
        setTimeout(() => {
          updateFilter({
            target: {
              dataset: {
                filterId: urlTargetId,
                parentId: urlParentId,
              },
            },
          })
        }, 100)
      }
    }
  }, [])

  useEffect(() => {
    setLoading(false)
  }, [postsData])

  return (
    <>
      <Filter
        categories={categories}
        updateFilter={updateFilter}
        activeFilters={activeFilters}
      />

      <PostList
        isLoading={isLoading}
        className="post-list"
        posts={posts}
        isFiltered={isFiltered}
      />
    </>
  )
}

export default FilterWithPostList
